<template>
  <div class="box">
    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <ClassList :list="list" @click="goToCourseIntroduction" />
      </van-list>
    </van-pull-refresh>
    <div class="bottom-placeholder"></div>
  </div>
</template>

<script>
import { getCurriculumTable } from '@/api/classRing'
import ClassList from '@/components/extend/classList'

export default {
  name: 'CurriculumTable',
  components: {
    ClassList
  },
  props: {
    type: { type: Number } // 班圈分类id
  },
  data() {
    return {
      userId: this.$store.state.user.userId,
      page: 1, // 当前页数
      list: [], // 显示的数据
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false // 加载出现错误
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.page = 1
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      let params = {
        userid: this.userId,
        autoid: this.type,
        page: this.page
      }
      getCurriculumTable(params)
        .then(res => {
          this.loading = false
          this.list.push(...res.list)
          this.pullRefresh = false
          if (this.page * 10 >= res.sum) {
            this.finished = true
          }
          this.page += 1
        })
        .catch(err => {
          this.loading = false
          this.pullRefresh = false
          this.error = true
        })
    },
    // ->课程介绍
    goToCourseIntroduction(item) {
      this.$router.push({
        path: '/classDetails/' + item.autoid
      })
    }
  }
}
</script>

<style scoped>
.class_ring_type_list {
  width: 93.6vw;
  height: 28.8vw;
  margin: 0 auto;
  border-bottom: 0.233vw solid #edeff2;
  display: flex;
  align-items: center;
}

.class_ring_type_list_left {
  width: 33.333vw;
  height: 22.133vw;
  border-radius: 6%;
  background-color: #c6c8cb;
}
.class_ring_type_list_left > img {
  width: 100%;
  height: 100%;
  border-radius: 6%;
  object-fit: cover;
}

.class_ring_type_list_rigrt {
  margin-left: 2vw;
  height: 80%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.type-title {
  font-size: 4.2vw;
  color: #303133;
}

.type-time {
  font-size: 2.9vw;
  color: #909399;
}
</style>
