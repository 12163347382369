<template>
  <div>
    <!-- 搜索 -->
    <van-sticky>
      <van-search placeholder="试试输入课程标题或专家名字吧" @click="shamSear" />
    </van-sticky>
    <!-- 轮播图 -->
    <div class="class_ring_head">
      <van-swipe :autoplay="playing ? 0 : 6000" indicator-color="#010101" @change="swiperChange">
        <van-swipe-item v-for="(item, i) in carousel" :key="'swipe' + i">
          <div class="carousel" @click="skip(item.url, item.bannerstatus)">
            <img :src="item.pic" v-if="item.bannerstatus !== 2" />
            <template v-else>
              <video-player
                class="video-player vjs-custom-skin"
                ref="videoPlayer"
                :playsinline="playsinline"
                :options="item.playerOptions"
                @canplay="onPlayerCanplay($event)"
                @pause="onPlayerPause($event)"
                @play="onPlayerPlay($event)"
              ></video-player>
            </template>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 内容 -->
    <div class="class_ring_type">
      <van-tabs
        v-model="active"
        line-width="4.533vw"
        title-inactive-color="#303133"
        color="#247dff"
        animated
        swipeable
        sticky
        :offset-top="54"
      >
        <van-tab :title="tab.name" v-for="(tab, i) in tabsTitle" :key="'tab' + i">
          <CurriculumsTable ref="classList" :type="tab.autoid" />
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import CurriculumsTable from '@/components/ClassRing/CurriculumsTable'
import { classRingType, getCarousel } from '@/api/classRing'
import { getOpenId } from '../util/auth'
import Record from './ClassRing/widget/courseHome/review'
import playCanplay from '@/util/playCanplay'

export default {
  name: 'classRing',
  components: { CurriculumsTable },
  data() {
    return {
      active: 0, // 当前tab下标
      tabsTitle: [], // tab的标题
      carousel: [], // 轮播图
      playsinline: false, //设备类型
      playing: false //正在播放
    }
  },
  methods: {
    async init() {
      let ua = navigator.userAgent.toLocaleLowerCase()
      if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
        // x5内核
        this.playsinline = false
      } else {
        // ios端
        this.playsinline = true
      }

      // 获取班圈分类
      const ring = await classRingType()
      this.tabsTitle = ring.list
      // 获取轮播图
      let carousel = await getCarousel()
      carousel.list.forEach(item => {
        if (item.bannerstatus === 2) {
          //视频
          item.playerOptions = this.getPlayerOptions(item)
        }
        this.carousel = carousel.list
      })
    },
    getPlayerOptions(item) {
      return {
        autoplay: false,
        muted: false,
        loop: false,
        preload: 'auto',
        language: 'zh-CN',
        aspectRatio: '16:9',
        fluid: true,
        width: '',
        notSupportedMessage: '此视频暂无法播放，请稍后再试',
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
          remainingTimeDisplay: false,
          fullscreenToggle: true //全屏按钮
        },
        poster: item.pic,
        sources: [
          {
            type: '',
            src: item.video
          }
        ]
      }
    },
    // 搜索
    shamSear() {
      this.$router.push({
        path: '/classSearch'
      })
    },
    //解决自动全屏
    onPlayerCanplay(player) {
      playCanplay()
    },
    // 轮播图跳转
    skip(url, status) {
      if (status == 0) return //不跳转
      if (url.includes('http')) {
        window.location.href = url
      } else {
        window.location.href = 'http://' + url
      }
    },
    onPlayerPlay(e) {
      this.playing = true
    },
    onPlayerPause(e) {
      this.playing = false
    },
    swiperChange() {
      if (this.playing) {
        this.$refs.videoPlayer[0].player.pause() //暂停视频播放
      }
    }
  },
  mounted() {
    // 自动登录
    if (getOpenId()) {
      this.$store.dispatch('user/loginByOpenId')
    }
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.class_ring_head {
  width: 93.6vw;
  height: 31.2vw;
  border-radius: 2.133vw;
  overflow: hidden;
  margin: 3.333vw auto;
  .carousel {
    height: 31.2vw;
    border-radius: 4vw;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    /deep/.video-player {
      height: 100%;
      .video-js.vjs-fluid {
        height: 100%;
        padding-top: 0;
        .vjs-big-play-button {
          font-size: 1.5em;
        }
        .vjs-control-bar {
          font-size: 2.133vw;
          border-radius: 0 0 3.2vw 3.2vw;
        }
      }
    }
  }
}

// .class_ring_type {

// }

// .class_ring_type_list_rigrt {
//   height: 70%;
//   display: flex;
//   flex-flow: column;
//   justify-content: space-between;
// }

// .class_ring_type_list_left {
//   width: 33.333vw;
//   height: 22.133vw;
//   border-radius: 6%;
//   background-color: red;
// }

// .type-title {
//   font-size: 4.267vw;
//   color: #303133;
// }

// .type-time {
//   font-size: 2.933vw;
//   color: #909399;
// }

// .class_ring_type_list {
//   width: 93.6vw;
//   height: 28.8vw;
//   margin: 0 auto;
//   border-bottom: 0.233vw solid #edeff2;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// }
// .class_ring_head {

// }
// .carousel{

// }
// .carousel>img{
// }
</style>
